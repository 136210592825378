@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/photo1.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.tab-container {
  padding-top: 80px;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* animation: slide-in 1s forwards; */
}

.tab {
  color: white;
  text-decoration: none;
  font-size: 24px;
  margin: 10px 0;
}

.tab-slide0 {
  opacity: 0; /* 初期状態で非表示に */
  animation: slide-in 1s forwards;
}

.tab-slide1 {
  opacity: 0; /* 初期状態で非表示に */
  animation: slide-in 1s forwards;
  animation-delay: 0.5s; /* 0.5秒後に表示 */
}

.tab-slide2 {
  opacity: 0; /* 初期状態で非表示に */
  animation: slide-in 1s forwards;
  animation-delay: 1s; /* 1秒後に表示 */
}

.tab-slide3 {
  opacity: 0; /* 初期状態で非表示に */
  animation: slide-in 1s forwards;
  animation-delay: 1.5s; /* 1.5秒後に表示 */
}

.tab-slide4 {
  opacity: 0; /* 初期状態で非表示に */
  animation: slide-in 1s forwards;
  animation-delay: 2.0s; /* 1.5秒後に表示 */
}

.tab-slide5 {
  opacity: 0;
  animation: slide-in 1s forwards;
  animation-delay: 3.0s;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.corsorhover:hover {
  animation: corsor-hover 1s forwards;
}

@keyframes corsor-hover {
  from {
    color: white;
  }
  to {
    color: #F2294E;
  }
}