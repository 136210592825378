h1 {
    color: white;
}

.includemargintoph1 {
    margin-top: 40px;
}

h2 {
    font-size: 32px;
    color: white;
}

p {
    font-size: 18px;
    color: white;
    margin-bottom: 12px;
}

.parent::-webkit-scrollbar {
    opacity: 0;
}